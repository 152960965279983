<template>
    <div class="container">
        <div class="footer clearfix mb-0 text-muted">
            <div class="float-start">
                <p>v 1.0.0</p>
            </div>
            <div class="float-end">
                <!-- <p>Under <a href="#">Development</a></p> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'footerItems',

    data() {
        return {}
    },

    mounted() {},

    methods: {},

    computed: {},
}
</script>
